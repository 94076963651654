<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit != null"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Excel'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
              fileName="Invoice file"
            ></Export>
            <ExportPDF
              v-b-popover.hover.topleft=""
              :exportText="'Download PDF'"
              :exportUrl="exportPDFUrl"
              :exportDisabled="exportPDFDisabled"
              :saveData="save_data_id"
              :totalItems="tableData.totalItems"
              fileName="Purchase Invoice"
            ></ExportPDF>
          </div>
          <CustomTable
            v-if="tableSubmit != null"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :sortName="sort_name"
            :tableObj="tableSubmit"
            :tableId.sync="tableData.tabId"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
            :options="options"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import Export from '@/components/Export/Index.vue';
import ExportPDF from '@/components/Export/PDF.vue';

import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'PurchaseInvoice',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    Export,
    ExportPDF
  },
  data() {
    return {
      // 高级搜索配置
      loading: false,
      exportUrl: '',
      exportPDFUrl: '/rest/api/purchase/download-overview-purchase-invoice-pdf',
      exportDisabled: false,
      exportPDFDisabled: false,
      exportErrorText: '',
      searchConfiguration: {
        invoice_status: {
          type: 'checked-all',
          class: 'text_color_all',
          text: 'Status',
          allSelected: true,
          md: 12,
          resetVal: [0, 1, 2],
          options: [
            { label: 'Pending', value: 0, class: 'text_orange_dashed' },
            { label: 'Processing', value: 1, class: 'text_blue_dashed' },
            { label: 'Completed', value: 2, class: 'text_green_dashed' },
            { label: 'Warning', value: 3, class: 'text_red_dashed' }
          ]
        },
        supplier: {
          type: 'select',
          text: 'Supplier Name',
          placeholder: this.$t('page.please_choose'),
          options: [],
          hideLoading: true,
          input: this.changeSupplier,
          onSearch: this.onSearch
        },
        channel: {
          type: 'select',
          text: 'Channel',
          placeholder: this.$t('page.please_choose'),
          hideLoading: true,
          options: []
        },
        reserve_no: {
          type: 'input',
          text: 'Reserve No.',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        purchase_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Purchase Date',
          limit: {
            // start: null,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 7))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        cps_order_number: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        invoice_no: {
          type: 'input',
          text: 'Invoice Number',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        invoice_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Invoice Date',
          limit: {
            // start: null,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 7))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        order_data: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Order Data',
          limit: {
            // start: null,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 7))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        keyword: {
          type: 'input',
          text: 'Keyword',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          {
            key: 'select',
            label: 'Select',
            variant: 'select',
            allSelect: false
          },
          {
            key: 'purchase_information',
            label: 'Purchase Information',
            html: 'Purchase <br> Information'
            // sortable: true
          },
          {
            key: 'order_information',
            label: 'Order Information'
            // sortDesc: true
          },
          {
            key: 'supplier_name',
            label: 'Supplier Name'
          },
          { key: 'channel', label: 'Channel' },
          {
            key: 'note',
            label: 'Note',
          },
          {
            key: 'image',
            label: 'Image',
            variant: 'w-110'
          },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },
          {
            key: 'qty',
            label: 'Purchase Quantity',
            html: 'Purchase <br> Quantity'
          },
          {
            key: 'mb_hk_invoice_number',
            label: 'MB HK Invoice Number',
          },
          {
            key: 'invoice_no',
            label: 'Invoice No.'
          },
          {
            key: 'invoice_date',
            label: 'Invoice Date'
          },
          {
            key: 'net_amount',
            label: 'Net Amount'
          },
          {
            key: 'vat_amount',
            label: 'VAT Amount'
          },
          {
            key: 'gross_amount',
            label: 'Gross Amount'
          },
          {
            key: 'freight_amount',
            label: 'Freight'
          },
          {
            key: 'refundable',
            label: 'VAT Refund'
          },
          {
            key: 'invoice_status',
            label: 'Status'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'purchase_invoice',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        invoice_status: [0, 1, 2],
        supplier: null,
        channel: null,
        reserve_no: null,
        purchase_id: null,
        purchase_date_from: null,
        purchase_date_to: null,
        invoice_date_from: null,
        invoice_date_to: null,
        cps_order_number: null,
        invoice_no: null,
        order_data_from: null,
        order_data_to: null,
        keyword: null,
        limit: 100,
        page: 1
      },
      sort_name: '',

      // 表格数据
      save_data: [],
      save_data_id: [],
      tiemID: null,
      options: {
        sales_channel: [],
        supplier_list: [],
        channel: []
      }
    };
  },

  methods: {
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    exportUrlFn(data) {
      console.log(data);
      this.exportUrl = `/rest/api/purchase/download-overview-purchase-invoice-list?${this.urlEncode(
        data
      )}`;
    },

    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          image_url,
          purchase_id,
          purchase_date,
          reserve_no,
          cps_order_number,
          order_date,
          cps_order_url,
          position,
          supplier_name,
          brand,
          brand_article_number,
          product_name_en,
          product_name,
          color,
          color_name,
          size,
          qty,
          invoice_no,
          invoice_date,
          vat_amount,
          gross_amount,
          freight_amount,
          refundable,
          invoice_status,
          purchase_invoice_id,
          net_amount,
          channel,
          note,
          mb_hk_invoice_number
        } = key;

        tableData.push({
          id,
          select: false,
          note,
          mb_hk_invoice_number,
          purchase_invoice_id: purchase_invoice_id,
          purchase_information: {
            purchase_id: purchase_id,
            purchase_date: purchase_date,
            reserve_no: reserve_no
          },
          order_information: {
            cps_order_number: cps_order_number,
            order_date: order_date,
            cps_order_url: cps_order_url,
            position: position
          },
          supplier_name: supplier_name,
          channel: channel,
          image: image_url,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size
            // pos_article_no: pos_article_no
          },
          qty: qty,
          invoice_no: invoice_no,
          invoice_date: invoice_date,
          vat_amount: vat_amount,
          gross_amount: gross_amount,
          freight_amount: freight_amount,
          refundable: refundable,
          net_amount: net_amount,
          invoice_status: this.searchConfiguration.invoice_status.options[
            invoice_status
          ].label,
          action: 'PurchaseInvoice'
        });
      });
      return tableData;
    },
    itemsFn() {
      this.CACHE.addCache(
        this.currentUser.uid,
        'PurchaseInvoice',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      return apiBus.purchaseInvoice
        .purchaseInvoiceList(this.tableSubmit)
        .then(data => {
          // console.log(data);
          this.loading = false;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          this.exportUrlFn(this.tableSubmit);
          return this.tableData.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach(itme => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });

      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }
    },
    checkboxChange(select, index) {
      var splice_index = this.save_data.indexOf(index);
      // console.log(index);
      if (select) {
        this.save_data.push(index);
      } else {
        this.save_data.splice(splice_index, 1);
      }

      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach(item => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach(item => {
          item.select = false;
        });
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    getIdArr(o) {
      this.save_data_id = [];
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        o.forEach(key => {
          this.save_data_id.push(this.tableData.items[key].purchase_invoice_id);
        });
      }, 500);
    },

    onSearch(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      apiBus.purchase
        .getSupplierList({
          keyword: search
        })
        .then(data => {
          vm.searchConfiguration.supplier.options = data.data.data =
            data.data.data.supplier_list['Other Supplier'];
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.searchConfiguration.supplier.options = [];
          loading(false);
        });
    }, 350),

    changeSupplier(e) {
      this.searchConfiguration.channel.options = [];
      this.tableSubmit.channel = null;
      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: e })
          .then(data => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.searchConfiguration.channel.hideLoading = true;
      }
    },

    init() {
      if (this.CACHE.ifCache('PurchaseInvoice')) {
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'PurchaseInvoice'
        );
        Object.keys(this.searchConfiguration).forEach(key => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
        this.$refs.TAdvancedSearch.visible = this.CACHE.getVisible(
          'PurchaseInvoice'
        );
      } else {
        this.tableSubmit = this.initialTableSubmit;
      }

      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: this.tableSubmit.supplier })
          .then(data => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch(error => {
            console.log(error);
          });
      }

      apiBus.purchase
        .getSupplierList()
        .then(data => {
          this.options.supplier_list =
            data.data.data.supplier_list['Other Supplier'];
          this.searchConfiguration.supplier.options =
            data.data.data.supplier_list['Other Supplier'];
        })
        .catch(error => {
          console.log(error);
          this.options.supplier_list = [];
          this.searchConfiguration.supplier.options = [];
        });
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },

  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('PurchaseInvoice', isJustShown);
      }
    });
    if (this.options.sales_channel.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex');
            this.options.sales_channel = data.data.data.sales_channel;
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        console.log('有数据');
        this.options.sales_channel = this.baseConfig.sales_channel;
      }
    }
  },
  watch: {
    save_data(o) {
      this.getIdArr(o);
    },
    async 'tableSubmit.supplier'(n, o) {
      if (n == null && o != null) {
        const { data } = await apiBus.purchase.getSupplierList();
        this.searchConfiguration.supplier.options =
          data.data.supplier_list['Other Supplier'];
      }
    }
  }
};
</script>
<style scoped></style>
